import 'blueimp-gallery/css/blueimp-gallery.min.css';
import blueimp from 'blueimp-gallery/js/blueimp-gallery.min.js';

export const lightbox = () => {
    const links = document.querySelectorAll('.lightbox-link');
    for (let count = 0; count < links.length; count++) {
        links[count].addEventListener('click', (event) => {
            const link = event.currentTarget.src;
            const options = { index: link, event: event };
            const id = event.currentTarget.dataset.lightbox;
            const links = document.querySelectorAll(`#lightbox_${id} a`);
            blueimp(links, options);
        })
    }
}
export const categoryFilter = () => {

    const filters = document.querySelectorAll('.job-examples-filter');
    for (let i = 0; i < filters.length; i++) {
      filters[i].addEventListener('click', () => {
        const eventTarget = event.currentTarget;

        $(filters).removeClass('active');

        eventTarget.classList.add('active');
        const elements = document.querySelectorAll('.service-models');
        const targetData = eventTarget.dataset.jobtype;
        for(let c = 0; c < elements.length; c++) {
          if (targetData === 'all') {
            elements[c].classList.remove('d-none');
          } else {
            if(elements[c].dataset.jobtype != targetData) {
              elements[c].classList.add('d-none');
            } else {
              elements[c].classList.remove('d-none');
            }
          }
        }
      })
    }
}
